import React from 'react';
import { Link } from 'react-router-dom';

import '../styles/Download.css'

// download page
function Download() {
  return (
    <div className="download">
      <Link to="/">
        <h1 className="logo">drop.photos</h1>
      </Link>
      <h2>Download App</h2>
      <div className="description">
        To get started, simply download the app from the links below. Then just scan the code and start transfering your photos with a single tap.
      </div>
      <div className="download-links">
        <Link to="/download/ios">iOS</Link>
        {' · '}
        <Link to="/download/android">Android</Link>
      </div>
      <div className="copyright">
        Copyright © {new Date().getFullYear()} Himanshu C.
      </div>
    </div>
  )
}

export default Download;