import React, { useEffect, useState } from 'react';
import { useSpring, animated, config } from 'react-spring'
import { Link } from 'react-router-dom';
import { io } from 'socket.io-client';
import { nanoid } from 'nanoid';
import QRCode from 'qrcode.react';

import '../styles/Home.css';

// socket instance
let socket = null;

// connection status
const Status = {
  Failed: 0,
  Disconnected: 1,
  WaitingOnPhone: 2,
  ConnectedToPhone: 3,
};

// return status text
function StatusText({ status }) {
  let text = 'Disconnected';
  let help = 'Not connected, check internet and refresh page.'
  switch (status) {
    case Status.Failed:
      text = 'Connection Failed';
      help = 'Connection failed, check your internet and refresh page.'
      break;
    case Status.WaitingOnPhone:
      text = 'Waiting for phone...';
      help = 'Scan QR code from app to connect.';
      break;
    case Status.ConnectedToPhone:
      text = 'Connected';
      help = 'Connected to phone. Send pictures from your phone';
      break;
    default:
  }

  return (
    <div title={help} className="status">
      {text}
    </div>
  )
}

// render the home page
function Home() {
  const url = 'https://drop.photos/d/';
  const code = localStorage.getItem('code') || nanoid(10);
  const [status, setStatus] = useState(Status.Disconnected);
  const [images, setImages] = useState([]);
  const [full, setFull] = useState(false);

  // fade up anim
  const animUp = useSpring({
    transform: 'translate(0px, 0px)', opacity: 1, 
    from: { transform: 'translate(0, 75px)', opacity: 0 },
    config: config.slow,
  });

  // close connection
  const closeClient = () => {
    if (socket) {
      setStatus(Status.Disconnected);
      socket.emit('comp:left');
      socket.close();
    }
  };

  const ImageBox = ({ item, index }) => {
    const fadeLeft = useSpring({
      transform: 'translate(0, 0)', opacity: 1, 
      from: { transform: index === 0 ? 'translate(-50px, 0)' : 'translate(0, 0)', opacity: 0 },
      config: config.molasses,
    });

    return (
      <div className="image-box" onClick={() => setFull(item)}>
        <animated.img style={fadeLeft} alt="incoming" className="image" src={item.base64} />
      </div>
    );
  }

  // start sockets on page-load
  useEffect(() => {
    socket = io('/photos', { query: { code } });

    // success, tell room comp joined
    socket.on('connect', () => {
      socket.emit('comp:joined', (phoneIn) => {
        // server will confirm if phone in room
        setStatus(phoneIn ? Status.ConnectedToPhone : Status.WaitingOnPhone);
      });
      // save code in localstorage
      localStorage.setItem('code', code);
    });
    // conn failed
    socket.on('connect_error', (err) => {
      setStatus(Status.Failed);
    });
    // disconnected
    socket.on('disconnected', () => {
      socket.emit('comp:left');
      setStatus(Status.Disconnected);
    });

    // phone joined, success
    socket.on('phone:joined', () => {
      setStatus(Status.ConnectedToPhone);
    });
    // phone left, blah
    socket.on('phone:left', () => {
      setStatus(Status.WaitingOnPhone);
    });

    // file data coming from phone
    socket.on('phone:file:info', (img) => {
      img.blur = true;
      img.base64 = 'data:image/jpeg;base64,' + img.base64;
      // todo time it took for image to arrive
      setImages(arr => [img, ...arr]);
    });
    // full file is coming, replace small
    socket.on('phone:file:data', (img) => {
      img.base64 = 'data:image/jpeg;base64,' + img.base64;
      setImages(arr => [img, ...arr.slice(1)]);
    });

    return closeClient;
  }, [code]);

  return (
    <div id="app" className="app">
      <StatusText status={status} />
      {status !== Status.ConnectedToPhone && (
        <animated.div style={animUp} className="home">
          <QRCode className="qr-code" bgColor="transparent" title={url+code} value={url+code} size={220} />
          <h1 className="logo">drop.photos</h1>
          <div className="download-links">
            <Link to="/download/ios">iOS</Link>
            {' · '}
            <Link to="/download/android">Android</Link>
          </div>
          <div className="hidden">
            Download app, scan code, transfer photos!
          </div>
          <div className="copyright">
            Copyright © {new Date().getFullYear()} Himanshu C.
          </div>
        </animated.div>
      )}
      {status === Status.ConnectedToPhone && (
        <div className="grid">
          {images?.length === 0 && (
            <div className="empty-message">
              Send photos from your phone to see<br /> them here or drop photos from your computer.
            </div>
          )}
          {images.map((item, index) => <ImageBox key={index} item={item} />)}
        </div>
      )}
      {full && (
        <div className="full-image-box" onClick={() => setFull(null)}>
          <img alt="full" className="image" src={full.base64} />
          <a href={full.base64} download={full.filename} className="save-button">
            Download Photo
          </a>
          <div className="close" onClick={() => setFull(null)}>
            &times;
          </div>
        </div>
      )}
    </div>
  );
}

export default Home;
